import mainStore from '@/store'
import subRentalModule from '@/store/main/orders/sub-rental'
import {
  CHECKBOX_LEF,
  FLAT_PICKER, L_CURRENCY_MASK_INPUT, L_TIME_PICKER_INPUT,
  SELECT_CHILD_INPUT,
  SELECT_INPUT,
  TEXT_INPUT,
  TEXTAREA_INPUT,
} from '@/views/components/DynamicForm/constants'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'

export default function config() {
  const MODULE_NAME = 'sub-rent'
  const MODULE_NAME_ORDERS = 'orders'

  const store = mainStore
  const model = subRentalModule
  const ACCESS_ABILITY_CREATE = { action: 'change', subject: 'Catalog' }

  const PAGE_TITLE = 'Sub-rental Order List'
  const CREATE_PAGE_TITLE = 'New Sub-rental Request for Quote'
  const ORDER_INFORMATION_TITLE = 'Order Information'

  const emptyCallback = () => {}

  const statusFilterTab = [
    {
      icon: 'LQuoteIconFill',
      query: 'quote',
    },
    {
      icon: 'LSignatureIconFill',
      query: 'signature',
    },

    {
      icon: 'LWarehouseIcon',
      query: 'in-warehouse',
    },
    {
      icon: 'LOutIcon',
      query: 'transfer',
    },
    {
      icon: 'LTruckIcon',
      query: 'on-truck',
    },
    {
      icon: 'LCreditCardIconFill',
      query: 'credit-card',
    },
  ]

  const storeMutationsList = [
    {
      mutation: 'GET',
      payload: {},
    },
    {
      mutation: 'SET_CUSTOMER_INFORMATION_FORM',
      payload: {
        attachments: [],
      },
    },
    {
      mutation: 'SET_DISPATCH_AND_RETURN_FORM',
      payload: {},
    },
    {
      mutation: 'SET_EVENT_INFORMATION_FORM',
      payload: {},
    },
    {
      mutation: 'SET_ORDER_ITEMS_RENTAL_TABLE',
      payload: {
        products: [],
      },
    },
    {
      mutation: 'SET_ON_ERROR',
      payload: {},
    },
    {
      mutation: 'SET_ORDER_ITEMS_OTHER_CHARGES_TABLE',
      payload: [],
    },
  ]

  const tableColumns = [
    {
      key: 'order_no',
      label: 'Order No.',
      thStyle: {
        width: '4%',
      },
      isSortable: true,
    },
    {
      key: 'state',
      label: 'State',
      thStyle: {
        width: '1%',
      },
      isSortable: true,
    },
    {
      key: 'internal_order_reference',
      label: 'PO/Reference',
      thStyle: {
        width: '2%',
      },
      isSortable: true,
    },
    {
      key: 'customer.cust_name_dba',
      label: 'From',
      thStyle: {
        width: '20%',
      },
      isSortable: true,
    },
    {
      key: 'rental_start',
      label: 'Rental Start',
      thStyle: {
        width: '8%',
      },
      isSortable: true,
    },
    {
      key: 'rental_end',
      label: 'Rental End ',
      thStyle: {
        width: '8%',
      },
      isSortable: true,
    },
  ]

  const quoteStatusBar = [
    {
      icon: 'LQuoteIconFill',
      title: 'Request for Quote',
      iconSize: '20',
    },
    {
      icon: 'LSignatureIconFill',
      title: 'Approve',
      iconSize: '20',
    },
    {
      icon: 'LCreditCardIconFill',
      title: 'Invoice',
      iconSize: '20',
    },
  ]

  const orderStatus = [
    {
      icon: 'LQuoteIconFill',
    },
    {
      icon: 'LSignatureIconFill',
    },
    {
      icon: 'LCreditCardIconFill',
    },
    {
      icon: 'LTruckIcon',
    },
    {
      icon: 'LWarehouseIcon',
    },
    {
      icon: 'LBoxSentIcon',
    },
  ]

  const customerInformation = {
    customer_id: {
      label: 'Supplier Name',
    },
    billing_contact: {
      type: SELECT_CHILD_INPUT,
      label: 'Billing Contact',
      store: 'billingContactOnPurchase',
      rules: 'required',
      firstSelect: true,
      options: {
        label: 'fullName',
      },
    },
    billing_phone: {
      type: TEXT_INPUT,
      label: 'Billing Phone',
      placeholder: ' ',
    },
    billing_email: {
      type: TEXT_INPUT,
      label: 'Billing Email',
      placeholder: ' ',
    },
    billing_address: {
      type: TEXTAREA_INPUT,
      label: 'Billing Address',
      placeholder: ' ',
    },
    payment_term_id: {
      type: SELECT_INPUT,
      label: 'Payment Terms',
      store: 'paymentTermList',
      fParams: { is_active: 1 },
      rules: 'required',
      options: {
        label: 'label',
      },
    },
    internal_account_notes: {
      type: TEXTAREA_INPUT,
      label: 'Internal account notes ( not visible to the supplier )',
      placeholder: ' ',
    },
    internal_order_reference: {
      type: TEXTAREA_INPUT,
      label: 'Internal Order Reference ( not visible to the supplier )',
      placeholder: 'Write notes here...',
    },
    external_order_reference: {
      type: TEXTAREA_INPUT,
      label: 'Order Notes ( visible to the supplier )',
      placeholder: 'Write notes here...',
    },
    attachments: {
      label: 'Attachments',
    },
  }

  const suggestionTableColumnsSupplier = [
    {
      key: 'cust_name_dba',
      label: 'Company',
    },
    {
      key: 'first_name',
      label: 'Contact',
      getValue: (value, data) => `
      ${getValueFromGivenObjectByKey(data, 'default_contact.first_name', '—')} 
      ${getValueFromGivenObjectByKey(data, 'default_contact.last_name', '')} 
      `,
    },
  ]

  // Dispatch and Return
  const DISPATCH_FORM_TITLE = 'Dispatch & Return'

  const dispatchAndReturnRequiredFields = [
    'return_address_id',
    'dispatch_warehouse_id',
    'dispatch_method',
    'dispatch_contact_id',
    'dispatch_address_id',
  ]

  const dispatchAndReturnFields = {
    dispatch_warehouse_id: {
      type: SELECT_INPUT,
      label: 'Dispatch Warehouse',
      store: 'warehouseList',
      rules: 'required',
      options: {
        label: 'label',
      },
    },
    dispatch_method: {
      type: SELECT_INPUT,
      label: 'Dispatch Method',
      store: 'dispatchAndReturnMethodsList',
      rules: 'required',
      options: {
        label: 'name',
      },
    },
    return_method: {
      type: SELECT_INPUT,
      label: 'Return Method',
      store: 'dispatchAndReturnMethodsList',
      // rules: 'required',
      options: {
        label: 'name',
      },
    },
    order_routing_order_returns_to_another_warehouse: {
      type: CHECKBOX_LEF,
      label: 'Order returns to another warehouse',
    },
    order_routing_allow_dispatch_of_items_from_warehouse_of_origin: {
      type: CHECKBOX_LEF,
      label: 'Allow dispatch of items from warehouse of origin',
    },
    return_warehouse_id: {
      type: SELECT_INPUT,
      label: 'Return Warehouse',
      store: 'warehouseList',
      rules: '',
      options: {
        label: 'label',
      },
    },
    internal_shipping_notes: {
      type: TEXTAREA_INPUT,
      label: 'Internal Shipping Notes (not visible to the customer)',
    },
    return_contact_is_different: {
      type: CHECKBOX_LEF,
      label: 'Return contact is different',
    },
    dispatch_contact_id: {
      type: SELECT_CHILD_INPUT,
      label: 'Dispatch Contact',
      store: 'shippingContact',
      rules: 'required',
      options: {
        label: 'fullName',
      },
    },
    dispatch_phone: {
      type: TEXT_INPUT,
      label: 'Dispatch Phone',
      placeholder: ' ',
    },
    dispatch_email: {
      type: TEXT_INPUT,
      label: 'Dispatch Email',
      placeholder: ' ',
    },
    return_contact_id: {
      type: SELECT_CHILD_INPUT,
      label: 'Return Contact',
      store: 'shippingContact',
      rules: 'required',
      options: {
        label: 'fullName',
      },
    },
    return_phone: {
      type: TEXT_INPUT,
      label: 'Return Phone',
      placeholder: ' ',
    },
    return_email: {
      type: TEXT_INPUT,
      label: 'Return Email',
      placeholder: ' ',
    },
    dispatch_address_id: {
      type: SELECT_CHILD_INPUT,
      label: 'Dispatch Address',
      store: 'dispatchAndReturnAddress',
      fParams: { is_active: 1, shipping: 1 },
      rules: 'required',
      options: {
        label: 'fullAddress',
      },
    },
    return_address_id: {
      type: SELECT_CHILD_INPUT,
      label: 'Return Address',
      fParams: { is_active: 1, shipping: 1 },
      store: 'dispatchAndReturnAddress',
      rules: 'required',
      options: {
        label: 'fullAddress',
      },
    },
  }

  // Sub rental period
  const SUB_RENTAL_PERIOD = 'Sub-rental period'
  const periodFields = {
    period_rental_start_dispatch: {
      type: FLAT_PICKER,
      label: 'Rental Start (dispatch)',
      rules: 'required',
      hasOperationHours: true,
      options: {
        config: {
          'is-calendar-icon': true,
          dateFormat: 'Y-m-d',
          allowInput: true,
        },
      },
    },
    period_rental_end_receive: {
      type: FLAT_PICKER,
      label: 'Rental End (receive)',
      rules: 'required',
      hasOperationHours: true,
      options: {
        config: {
          'is-calendar-icon': true,
          dateFormat: 'Y-m-d',
          allowInput: true,
        },
      },
    },
    event_reference: {
      type: TEXT_INPUT,
      label: 'Event Reference',
    },
    period_rental_dispatch_start_time: {
      type: L_TIME_PICKER_INPUT,
      label: 'Dispatch Time',
      rules: 'required',
    },
    period_rental_receive_end_time: {
      type: L_TIME_PICKER_INPUT,
      label: 'Receive Time',
      rules: 'required',
    },
  }

  const periodRequiredFields = [
    'period_rental_start_dispatch',
    'period_rental_end_receive',
  ]

  // clear store
  function onClear() {
    for (let i = 0; i < storeMutationsList.length; i++) {
      this.$store.commit(`${MODULE_NAME}/${storeMutationsList[i].mutation}`, storeMutationsList[i].payload)
    }
  }

  const OTHER_CHARGES_OR_ITEMS_TITLE = 'Other Charges/Items'
  const tableColumnsOtherCharges = [
    {
      key: 'actions',
      label: '',
      thStyle: { width: '5%' },
    },
    {
      key: 'name',
      label: 'Name',
      thStyle: { width: '58%' },
    },
    {
      key: 'units',
      label: 'Units',
      thStyle: { width: '8%', textAlign: 'center' },
    },
    {
      key: 'price',
      label: 'Price',
      thStyle: { width: '8%', textAlign: 'center' },
    },
    {
      key: 'discount',
      label: 'Discount',
      thStyle: { width: '8%', textAlign: 'center' },
    },
    {
      key: 'taxes',
      label: 'Taxes',
      thStyle: { width: '8%', textAlign: 'center' },
    },
    {
      key: 'totals',
      label: 'Subtotal',
      thStyle: { width: '10%', textAlign: 'center' },
    },
  ]
  const tableColumnsOtherChargesOnIsNewDirectRequest = [
    {
      key: 'actions',
      label: '',
      thStyle: { width: '5%' },
    },
    {
      key: 'name',
      label: 'Name',
      thStyle: { width: '58%' },
    },
    {
      key: 'units',
      label: 'Units',
      thStyle: { width: '8%', textAlign: 'center' },
    },
  ]
  const chargeModel = {
    name: '',
    unit: '1',
    discount: null,
    subtotal: null,
    tax: null,
    totals: null,
  }

  const chargeModelFields = {
    name: {
      type: TEXT_INPUT,
      label: '',
    },
    unit: {
      type: TEXT_INPUT,
      label: '',
    },
    price: {
      type: L_CURRENCY_MASK_INPUT,
      label: '',
      placeholder: '$0',
    },
    discount: {
      type: L_CURRENCY_MASK_INPUT,
      label: '',
      placeholder: '$0',
    },
    subtotal: {
      type: L_CURRENCY_MASK_INPUT,
      label: '',
      placeholder: '$0',
    },
    taxes: {
      type: L_CURRENCY_MASK_INPUT,
      label: '',
      placeholder: '$0',
    },
    totals: {
      type: L_CURRENCY_MASK_INPUT,
      label: '',
      placeholder: '$0',
    },
  }
  return {
    store,
    model,
    statusFilterTab,
    PAGE_TITLE,
    orderStatus,
    MODULE_NAME,
    tableColumns,
    quoteStatusBar,
    ACCESS_ABILITY_CREATE,
    CREATE_PAGE_TITLE,
    customerInformation,
    ORDER_INFORMATION_TITLE,
    MODULE_NAME_ORDERS,
    suggestionTableColumnsSupplier,
    DISPATCH_FORM_TITLE,
    dispatchAndReturnRequiredFields,
    dispatchAndReturnFields,
    periodFields,
    periodRequiredFields,
    SUB_RENTAL_PERIOD,
    onClear,
    tableColumnsOtherCharges,
    tableColumnsOtherChargesOnIsNewDirectRequest,
    OTHER_CHARGES_OR_ITEMS_TITLE,
    chargeModel,
    chargeModelFields,
    emptyCallback,
  }
}
